<script lang="ts" setup>
import constants from '~/constants'
import ImageType from '~/enums/image-type'
import helpers from '~/helpers'

const imageTypes = [ImageType.Thumbnail360p, ImageType.Thumbnail240p, ImageType.Original]

const props = withDefaults(
  defineProps<{
    data: any
    hasBorder?: boolean
    needToDisplayName?: boolean
    path: string
  }>(),
  { hasBorder: false, needToDisplayName: true }
)

const imageUrl = computed(() => {
  const imageUrls = helpers.getImageUrls(props.data.relationships.images.data)

  return imageUrls ? helpers.getImageUrl(imageUrls, imageTypes) : null
})
const name = computed(() => props.data.attributes.name)
</script>

<template>
  <div class="card-with-image" :class="{ 'has-border': hasBorder }">
    <NuxtLink class="card-with-image__image" :to="path">
      <div class="card-with-image__image-inner">
        <img v-if="imageUrl" v-lazy="imageUrl" :alt="name" loading="lazy">
      </div>
    </NuxtLink>
    <div v-if="needToDisplayName" class="card-with-image__name">
      <NuxtLink :to="path">
        {{ name }}
      </NuxtLink>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import 'assets/css/variables';

.card-with-image {
  background: white;
  border-radius: 12px;
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  height: 100%;
  overflow: hidden;
  position: relative;

  &.has-border {
    border: 1px solid mix($main-gray-color, white, 20%);
  }

  &__image & {
    border: none;
    display: block;
    position: relative;

    &__image-inner {
      height: 0;
      padding-bottom: 56.25%;
      position: relative;

      img {
        height: 100%;
        min-height: 100%;
        object-fit: cover;
        position: absolute;
        width: 100%;
      }
    }
  }

  &__name {
    padding: 16px;

    @media screen and (max-width: 480px) {
      font-size: 12px;
      line-height: 14px;
      padding: 8px;
    }

    a {
      color: inherit;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
</style>
